import { DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Card,
  Col,
  Comment,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'
import * as R from 'ramda'
import { FormInstance } from 'rc-field-form/lib/interface'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { getSessionIdByReservationId } from '../../services/evcharger/web-session'
import {
  IEditNoteDocument,
  INoteDocument,
  createNote,
  deleteNote,
  getNoteList,
  updateNote,
} from '../../services/evreservation'
import { AuthContext } from './../../contexts/Auth'

const { Text } = Typography
const RightCol = styled(Col)`
  text-align: right;
  word-wrap: break-word;
`

interface IReservationDetail {
  reservationId: number
  onClose(): void
}

interface INoteForm {
  detail: string
  noteType: 'OTHER' | 'REFUND'
  refundAmount?: number
}

interface ISnapShot {
  notifyRequired: boolean
}

const NoteCard: React.FC<{
  note: INoteDocument
  onUpdate(reservationId: number): void
  onEdit(_id: string): void
}> = ({ note, onUpdate, onEdit }) => {
  const authContext = useContext(AuthContext)
  const { _id, reservationId } = note
  const tagColor = () => {
    switch (note.noteType) {
      case 'OTHER':
        return 'blue'
      case 'REFUND':
        return 'magenta'
      default:
        return 'blue'
    }
  }

  const currentUserId = authContext?.user.userid
  const authorNoteUserId = note?.user?.userId

  const actions = [
    <Tag color={tagColor()}>{note.noteType}</Tag>,
    <Tooltip key="comment-basic-like" title="Edit">
      <span>
        <Popconfirm
          title="Are you sure to edit this note?"
          onConfirm={() => {
            onEdit(_id)
          }}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
          <span className="comment-action">Edit</span>
        </Popconfirm>
      </span>
    </Tooltip>,
  ]

  if (currentUserId === authorNoteUserId) {
    actions.push(
      <Tooltip key="comment-basic-like" title="Delete">
        <span>
          <Popconfirm
            title="Are you sure to delete this note?"
            onConfirm={() => {
              if (_id) {
                deleteNote(authContext, { _id }).then((result) => {
                  if (result?.result) {
                    onUpdate(reservationId)
                    message.success('Delete note successfully')
                  }
                })
              }
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
            <span className="comment-action">Delete</span>
          </Popconfirm>
        </span>
      </Tooltip>,
    )
  }
  return (
    <>
      <Comment
        actions={actions}
        author={<a>{note.user.userName}</a>}
        avatar={<Avatar alt={note.user.userName}>{note.user.userName.charAt(0)}</Avatar>}
        content={
          <>
            {note.detail}
            {note.noteType === 'REFUND' && (
              <>
                <br />
                <Text mark>Refunded: {note.refundAmount ?? '-'} ฿</Text>
              </>
            )}
          </>
        }
        datetime={
          <Tooltip title={moment(note.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
            <span>{moment(note.createdAt).fromNow()}</span>
          </Tooltip>
        }
      />
    </>
  )
}

const AddNote: React.FC<{
  isModalVisible: boolean
  onClose(): void
  reservationId: number
  onCreateNote(data: INoteForm): void
  formRef: any
  spinning?: boolean
}> = ({ isModalVisible, onClose, reservationId, onCreateNote, formRef, spinning }) => {
  const [showRefund, setShowRefund] = useState(false)
  const onChangeNoteType = (value: string) => {
    if (value === 'REFUND') {
      setShowRefund(true)
    } else {
      setShowRefund(false)
    }
  }
  const onFinish = (e: INoteForm) => {
    let formValues = e
    const { noteType } = e
    if (noteType !== 'REFUND') {
      formValues = R.omit(['refundAmount'], e)
    }
    const data = {
      ...formValues,
      reservationId,
    }
    onCreateNote(data)
  }
  return (
    <Modal
      title={`Add note for reservation no. ${reservationId}`}
      visible={isModalVisible}
      onCancel={() => {
        onClose()
      }}
      footer={null}
    >
      <Spin spinning={spinning}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          initialValues={{ noteType: 'OTHER', detail: '', refundAmount: 0 }}
          onFinish={onFinish}
          ref={formRef}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item label="Category" name="noteType">
            <Select defaultValue="OTHER" style={{ width: 120 }} onChange={onChangeNoteType}>
              <Select.Option value="OTHER">Other</Select.Option>
              <Select.Option value="REFUND">Refund</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Detail"
            name="detail"
            rules={[{ required: true, message: 'Please input your detail!' }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          {showRefund && (
            <Form.Item
              label="Refund Price"
              name="refundAmount"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <InputNumber prefix="฿" />
            </Form.Item>
          )}

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button htmlType="button" onClick={() => onClose()}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

const EditNote: React.FC<{
  isModalVisible: boolean
  onClose(): void
  reservationId: number
  onEditNote(data: INoteForm): void
  formRef: any
  note?: IEditNoteDocument
  spinning?: boolean
}> = ({ isModalVisible, onClose, reservationId, onEditNote, formRef, note, spinning }) => {
  const [showRefund, setShowRefund] = useState(false)
  const onChangeNoteType = (value: string) => {
    if (value === 'REFUND') {
      setShowRefund(true)
    } else {
      setShowRefund(false)
    }
  }
  useEffect(() => {
    if (note?.noteType === 'REFUND') {
      setShowRefund(true)
    } else {
      setShowRefund(false)
    }
    formRef?.current?.setFieldsValue({
      detail: note?.detail,
      noteType: note?.noteType,
      refundAmount: note?.refundAmount,
    })
  }, [note])
  const onFinish = (e: INoteForm) => {
    let formValues = e
    const { noteType } = e
    if (noteType !== 'REFUND') {
      formValues = R.omit(['refundAmount'], e)
    }
    const data = {
      ...formValues,
      _id: note?._id,
    }
    onEditNote(data)
  }
  return (
    <Modal
      title={`Edit note for reservation no. ${reservationId}`}
      visible={isModalVisible}
      onCancel={() => {
        onClose()
      }}
      footer={null}
    >
      <Spin spinning={spinning}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          initialValues={{
            noteType: note?.noteType,
            detail: note?.detail,
            refundAmount: note?.refundAmount,
          }}
          onFinish={onFinish}
          ref={formRef}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item label="Category" name="noteType">
            <Select
              defaultValue={note?.noteType}
              style={{ width: 120 }}
              onChange={onChangeNoteType}
            >
              <Select.Option value="OTHER">Other</Select.Option>
              <Select.Option value="REFUND">Refund</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Detail"
            name="detail"
            rules={[{ required: true, message: 'Please input your detail!' }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          {showRefund && (
            <Form.Item
              label="Refund Price"
              name="refundAmount"
              rules={[{ required: true, message: 'Please input refund amount' }]}
            >
              <InputNumber prefix="฿" />
            </Form.Item>
          )}

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button htmlType="button" onClick={() => onClose()}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

interface IReservationDetailState {
  noteLists: INoteDocument[]
  isAddNoteVisible: boolean
  isEditNoteVisible: boolean
  isLoadingNote: boolean
  isAddingNote: boolean
  note?: INoteDocument
  sessionId: string
}

class ReservationDetail extends React.Component<IReservationDetail, IReservationDetailState> {
  static contextType = AuthContext
  formRef: React.RefObject<FormInstance> = React.createRef()
  editFormRef: React.RefObject<FormInstance> = React.createRef()
  state = {
    noteLists: [],
    isAddNoteVisible: false,
    isEditNoteVisible: false,
    isLoadingNote: false,
    isAddingNote: false,
    note: undefined,
    sessionId: '',
  }
  getNote = (reservationId: number): void => {
    if (reservationId !== 0) {
      this.setState({ isLoadingNote: true })
      getNoteList(this.context, { reservationId })
        .then((result) => {
          const noteLists = result?.data?.docs
          this.setState({ noteLists, isLoadingNote: false })
        })
        .catch((error) => {
          message.error(`Can't get note list: ${error}`)
          this.setState({ isLoadingNote: false })
        })
    }
  }

  createNote = (body: INoteDocument): void => {
    this.setState({ isAddingNote: true })
    createNote(this.context, body)
      .then(() => {
        this.setState({ isAddingNote: false })
        message.success(`Create new note successfully`)
        this.getNote(this.props.reservationId)
        this.formRef?.current?.resetFields()
        this.setState({ isAddNoteVisible: false })
      })
      .catch((error) => {
        this.setState({ isAddingNote: false })
        message.error(`Can't create new note: ${error}`)
      })
  }

  editNote = (body: INoteDocument): void => {
    this.setState({ isAddingNote: true })
    updateNote(this.context, body)
      .then(() => {
        this.setState({ isAddingNote: false })
        message.success(`Edit note successfully`)
        this.getNote(this.props.reservationId)
        this.formRef?.current?.resetFields()
        this.setState({ isEditNoteVisible: false })
      })
      .catch((error) => {
        message.error(`Can't edit note: ${error}`)
        this.setState({ isAddingNote: false })
      })
  }

  getSessionId = (reservationId: number): void => {
    getSessionIdByReservationId(this.context, reservationId)
      .then((res: string) => {
        this.setState((prev) => ({ ...prev, sessionId: res }))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  componentDidMount(): void {
    this.getNote(this.props.reservationId)
    this.getSessionId(this.props.reservationId)
  }

  getSnapshotBeforeUpdate(prevProps: IReservationDetail): ISnapShot {
    return { notifyRequired: prevProps.reservationId !== this.props.reservationId }
  }

  componentDidUpdate(
    _prevProps: IReservationDetail,
    _prevState: IReservationDetailState,
    snapshot: ISnapShot,
  ): void {
    if (snapshot.notifyRequired) {
      this.getNote(this.props.reservationId)
    }
    if (_prevProps.reservationId !== this.props.reservationId) {
      this.getSessionId(this.props.reservationId)
      this.setState((prev) => ({ ...prev, sessionId: '' }))
    }
  }

  onEdit = (id: string): void => {
    const thisNote = this.state.noteLists.filter((item: INoteDocument) => item._id === id)
    if (thisNote.length > 0) {
      this.setState({ isEditNoteVisible: true, note: thisNote[0] })
    } else {
      message.error(`Something went wrong!!`)
    }
  }

  onClose = (): void => {
    this.setState({ isAddNoteVisible: false, isEditNoteVisible: false })
  }

  render(): JSX.Element {
    return (
      <>
        <AddNote
          isModalVisible={this.state.isAddNoteVisible}
          onClose={this.onClose}
          reservationId={this.props.reservationId}
          onCreateNote={this.createNote}
          formRef={this.formRef}
          spinning={this.state.isAddingNote}
        />
        <EditNote
          isModalVisible={this.state.isEditNoteVisible}
          onClose={this.onClose}
          reservationId={this.props.reservationId}
          onEditNote={this.editNote}
          formRef={this.editFormRef}
          spinning={this.state.isAddingNote}
          note={this.state.note}
        />

        <Card
          title={`#${this.props.reservationId}`}
          style={{ width: '100%' }}
          extra={
            <a href="#" onClick={() => this.props.onClose()}>
              Close
            </a>
          }
        >
          <Row>
            <Col span={12}>
              <h3>Note ({`${this.state.noteLists.length}`})</h3>
            </Col>
            <RightCol span={12}>
              {/* <a href="#" onClick={() => this.setState({ isAddNoteVisible: true })}>
                Add note
              </a> */}
            </RightCol>
          </Row>

          {this.state.isLoadingNote ? (
            <Row justify="center" align="middle">
              <Col span={4}>
                <Spin />
              </Col>
            </Row>
          ) : (
            [
              this.state.noteLists.map((note) => {
                return (
                  <Row>
                    <NoteCard note={note} onUpdate={this.getNote} onEdit={this.onEdit} />
                  </Row>
                )
              }),
              this.state.noteLists.length === 0 && (
                <Result icon={<MinusCircleOutlined />} title="Empty note" />
              ),
            ]
          )}
        </Card>
      </>
    )
  }
}

export default ReservationDetail
