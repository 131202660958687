import { DatePicker, DatePickerProps } from 'antd'
import moment from 'moment'

type DateDatePickerProps = DatePickerProps & {
  id?: string
  value?: Date | null
  onChange?: (value?: Date | null) => void
}
export const DateDatePicker = ({
  id,
  value,
  onChange,
  ...props
}: DateDatePickerProps): JSX.Element => {
  const valueAsMoment = value && moment(value).isValid() ? moment(value) : undefined
  function handleChange(m: moment.Moment | null) {
    if (onChange) {
      if (m) {
        return onChange(m.toDate())
      }
      return onChange(m)
    }
  }

  return <DatePicker id={id} value={valueAsMoment} onChange={(m) => handleChange(m)} {...props} />
}
