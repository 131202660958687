import { EyeOutlined } from '@ant-design/icons'
import { Col, Collapse, Descriptions, Modal, Row, Space, Table, Typography } from 'antd'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Notification from '../../components/Notification'
import { ViewButton } from '../../components/StyledComponent'
import { AuthContext } from '../../contexts/Auth'
import { getEvCharger } from '../../services/evcharger'
import { getPricingList } from '../../services/pricing'
import { mapPricingData } from '../Pricing'
import CreateCharger from './index'

const { Title } = Typography
const { Panel } = Collapse

interface IChargerData {
  chargerCode: string
  _id: number
  deviceInfo: []
  stationId: number
  chargerStatus: string
  deviceType: string
  deviceProtocol: string
  serviceGroup: string
  connectors: []
}

const HeadText = styled.h4`
  font-weight: 500;
  display: inline-flex;
`
function mapChargerData(data: IChargerData[]) {
  const chargerResult = data.map((item) => {
    const key = item._id
    const {
      chargerCode,
      deviceInfo,
      stationId,
      _id,
      chargerStatus,
      deviceType,
      deviceProtocol,
      serviceGroup,
      connectors,
    } = item
    return {
      chargerCode,
      key,
      deviceInfo,
      stationId,
      _id,
      chargerStatus,
      deviceType,
      deviceProtocol,
      serviceGroup,
      connectors,
    }
  })
  return chargerResult
}
const mockData = {
  vendor: '',
  model: '',
  serialNumber: '',
  firmwareVersion: '',
  stationId: '',
  chargerCode: '',
  chargerStatus: '',
  deviceType: '',
  deviceProtocol: '',
  serviceGroup: '',
  priceId: '',
}

const ViewCharger = (): JSX.Element => {
  const [changeComponent, setChangeComponent] = useState(true)
  const [chargerList, setChargerList] = useState<any>([])
  const [loadingCharger, setLoadingCharger] = useState(true)
  const [dataCharger, setDataCharger] = useState<any>([])
  const [dataConnector, setDataConnector] = useState<any>([])
  const [visible, setVisible] = useState(false)
  // const [paramToDelete, setParamToDelete] = useState<any>(null)
  const [current, setCurrent] = useState(1)
  const [totalDocs, setTotalDocs] = useState(1)
  const [limitDocs, setLimitDocs] = useState(0)
  // const [isModalDelete, setIsModalDelete] = useState(false)
  const [isModalPricing, setIsModalPricing] = useState(false)
  const [pricingList, setPricingList] = useState<any>([])
  const Auth = useContext(AuthContext)

  useEffect(() => {
    getDataFromApi(current)
  }, [])
  // const mapChargerDataId = (data: any) => {
  //   const { _id } = data
  //   setParamToDelete(_id)
  //   return _id
  // }
  const getDataFromApi = (page: number) => {
    getEvCharger(Auth, { page }).then((result: any) => {
      const { docs, totalDocs, limit } = result?.data
      const charger = mapChargerData(docs)
      setTotalDocs(totalDocs)
      setLimitDocs(limit)
      setChargerList(charger)
      setLoadingCharger(false)
    })
    getPricingList(Auth)
      .then((result: any) => {
        const { docs } = result?.data
        const pricing = mapPricingData(docs)
        setPricingList(pricing)
      })
      .catch((error) => {
        Notification(`${error}`, 'error')
      })
  }
  const handleChangePagination = (page: number) => {
    setCurrent(page)
    getDataFromApi(page)
  }
  // function handleChangePage(value: any) {
  //   setChangeComponent(false)
  //   setDataCharger(value)
  // }
  // const showModalDelete = async (item: any) => {
  //   await setIsModalDelete(true)
  //   await setParamToDelete(null)
  //   await mapChargerDataId(item)
  // }
  // const handleOkDelete = async () => {
  //   setIsModalDelete(false)
  //   // await deleteEvCharger(Auth, paramToDelete)
  //   //   .then((res: any) => {
  //   //     Notification('Delete Successfully', 'success')
  //   //     getEvCharger
  //   //   })
  //   //   .catch((error: any) => {
  //   //     Notification(`${error}`, 'error')
  //   //   })
  //   // await setLoadingCharger(true)
  //   // await getDataFromApi(current)
  // }

  // const handleCancelDelete = () => {
  //   setIsModalDelete(false)
  // }
  const handleShowDataPricing = () => {
    setIsModalPricing(true)
  }

  const handleShowData = (value: any) => {
    setVisible(true)
    const {
      deviceInfo,
      stationId,
      chargerCode,
      chargerStatus,
      deviceType,
      deviceProtocol,
      serviceGroup,
      connectors,
    } = value || {}
    setDataConnector(connectors)
    const { vendor, model, serialNumber, firmwareVersion } = deviceInfo || {}
    mockData.vendor = vendor
    mockData.model = model
    mockData.serialNumber = serialNumber
    mockData.firmwareVersion = firmwareVersion
    mockData.stationId = stationId
    mockData.chargerCode = chargerCode
    mockData.chargerStatus = chargerStatus
    mockData.deviceType = deviceType
    mockData.deviceProtocol = deviceProtocol
    mockData.serviceGroup = serviceGroup
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: 'chargerId',
    },
    {
      title: 'Charger Code',
      dataIndex: 'chargerCode',
      key: 'chargerCode',
    },
    {
      title: 'Status',
      dataIndex: 'chargerStatus',
      key: 'chargerStatus',
    },
    {
      title: 'Service Group',
      dataIndex: 'serviceGroup',
      key: 'serviceGroup',
    },
    {
      title: 'Device Protocol',
      dataIndex: 'deviceProtocol',
      key: 'deviceProtocol',
    },
    {
      title: 'Action',
      key: '_id',
      render: (item: any, index: any) => (
        <>
          <Space size="middle">
            <ViewButton shape="round" onClick={() => handleShowData(item)} key={item} value={item}>
              <EyeOutlined /> View
            </ViewButton>
          </Space>
        </>
      ),
    },
  ]
  return (
    <>
      {changeComponent ? (
        <>
          {/* <Modal title="Confirm to Delete" visible={isModalDelete} closable={false} footer={null}>
            <h2>
              <DeleteTwoTone /> Delete Charger?...
            </h2>
            <div style={{ position: 'relative', bottom: 0, left: '20rem' }}>
              <ViewButton
                shape="round"
                style={{ width: '80px', left: '-4px' }}
                onClick={handleOkDelete}
              >
                Ok
              </ViewButton>
              <DeleteButton shape="round" key="close" onClick={handleCancelDelete}>
                Cancel
              </DeleteButton>
            </div>
          </Modal> */}

          <Row>
            <Title level={1}>Charger List</Title>
            <Title level={4} style={{ position: 'absolute', right: '105px' }}>
              Total Charger : {loadingCharger !== false ? loadingCharger : totalDocs}
            </Title>
          </Row>
          <hr style={{ marginBottom: 20 }} />

          <HeadText style={{ fontSize: 15, marginLeft: 10 }}></HeadText>

          <Table
            columns={columns}
            dataSource={chargerList}
            loading={loadingCharger}
            pagination={{
              pageSize: limitDocs,
              current: current,
              total: totalDocs,
              onChange: handleChangePagination,
            }}
          />
        </>
      ) : (
        <CreateCharger dataCharger={dataCharger} />
      )}
      <Modal
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
      >
        <Descriptions title="Charger Detail" labelStyle={{ fontWeight: 'bolder' }} bordered>
          <Descriptions.Item label="Device Info" span={1}>
            <p>
              <HeadText>vendor :</HeadText>
              {mockData.vendor}
              <hr />
              <HeadText>model : </HeadText>
              {mockData.model}
              <hr />
              <HeadText>serial :</HeadText>
              {mockData.serialNumber}
              <hr />
              <HeadText>firmware : </HeadText> {mockData.firmwareVersion}
              <hr />
            </p>
          </Descriptions.Item>
          <Descriptions.Item label="connectors" span={4}>
            {dataConnector.map((item: any) => (
              <Collapse>
                <Panel header="connectors info" key="1">
                  <Row>
                    <Col md={12} sm={24} xs={24}>
                      <p>
                        <Row>
                          <HeadText>status : </HeadText>
                          {item.connectorStatus}
                        </Row>
                        <Row>
                          <HeadText>maxKw : </HeadText>
                          {item.maxKw}
                        </Row>
                        <Row>
                          <HeadText>selector : </HeadText>
                          {item.connectorSelector}
                        </Row>
                      </p>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <HeadText>connectorType : </HeadText>
                      <p>code: {item.connectorType.code}</p>
                      <p>name: {item.connectorType.name}</p>
                      <p>current: {item.connectorType.currentType}</p>
                    </Col>
                  </Row>
                  <Row>
                    {item?.priceId ? (
                      <>
                        <ViewButton
                          shape="round"
                          onClick={handleShowDataPricing}
                          key={item}
                          value={item}
                        >
                          <EyeOutlined /> pricing
                        </ViewButton>
                        <Modal
                          centered
                          visible={isModalPricing}
                          onOk={() => setIsModalPricing(false)}
                          onCancel={() => setIsModalPricing(false)}
                          footer={null}
                          width={700}
                        >
                          {pricingList?.map((price: any) =>
                            item?.priceId === price?._id ? (
                              <>
                                <Row>
                                  <Title level={5}>Pricing Info </Title>
                                  <p style={{ margin: 2 }}>( ID: {item?.priceId} )</p>
                                </Row>
                                <Descriptions layout="vertical" bordered>
                                  <Descriptions.Item label="Price Code">
                                    {price?.priceCode}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Price Description">
                                    {!`${price?.priceDescription}` == null
                                      ? `${price?.priceDescription}`
                                      : 'not specified'}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="electricPrice">
                                    {price?.electricPrice} ฿ / kWh
                                  </Descriptions.Item>
                                  <Descriptions.Item label="PenaltyFee Per Hour">
                                    {price?.penaltyFeePerHour} ฿ / kWh
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Base Price">
                                    <b>serviceFee:&nbsp;&nbsp;</b>
                                    {price?.serviceFee} ฿ / hour
                                  </Descriptions.Item>
                                  <Descriptions.Item label="hourPrice">
                                    <b>serviceFee:&nbsp;&nbsp;</b>
                                    {price?.hourPrice?.serviceFee}
                                    ฿ / hour <br />
                                    <b>parkingFee:&nbsp;&nbsp;</b>
                                    {price?.hourPrice?.parkingFee} ฿ / hour
                                  </Descriptions.Item>
                                </Descriptions>
                              </>
                            ) : (
                              ''
                            ),
                          )}
                        </Modal>
                      </>
                    ) : (
                      <p style={{ color: 'red' }}>! This connector not pricing</p>
                    )}
                  </Row>
                </Panel>
              </Collapse>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="StationID">
            <p>{mockData.stationId}</p>
          </Descriptions.Item>
          <Descriptions.Item label="Charger Code">
            <p>{mockData.chargerCode}</p>
          </Descriptions.Item>
          <Descriptions.Item label="Charger Status">
            <p>{mockData.chargerStatus}</p>
          </Descriptions.Item>
          <Descriptions.Item label="Device Type">
            <p>{mockData.deviceType}</p>
          </Descriptions.Item>
          <Descriptions.Item label="Device Protocol">
            <p>{mockData.deviceProtocol}</p>
          </Descriptions.Item>
          <Descriptions.Item label="Service Group">
            <p>{mockData.serviceGroup}</p>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  )
}

export default ViewCharger
