import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, InputNumber, Popover, Row, Select, Typography } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { ButtonGlobal, ViewButton } from '../../components/StyledComponent'
import { AuthContext } from '../../contexts/Auth'
import { IConnectorType, ICreateCharger } from '../../services/evcharger'
import { getEvStationList } from '../../services/evstation'
import { getPricingList } from '../../services/pricing'
import ViewCharger from './viewCharger'
const { Title } = Typography
const { Option } = Select

interface IPostCharger {
  controlDevice: any
  stationId: number
  chargerCode: string
  chargerStatus: string
  deviceType: string
  serviceGroup: string
  deviceProtocol: string
  maxKw: number
  connectorStatus: string
  vendor: string
  model: string
  serialNumber: string
  firmwareVersion: string
  connectors: any
  connectorSelector: string
  connectorsList?: any
}

export const connectorTypes = [
  {
    code: 'TYPE_2',
    name: 'Type 2 (AC)',
    currentType: 'AC',
  },
  {
    code: 'TYPE_1',
    name: 'Type 1 (AC)',
    currentType: 'AC',
  },
  {
    code: 'TYPE_PLUG',
    name: 'Plug (AC)',
    currentType: 'AC',
  },
  {
    code: 'CCS',
    name: 'CCS (DC)',
    currentType: 'DC',
  },
  {
    code: 'CHADEMO',
    name: 'CHAdeMo (DC)',
    currentType: 'DC',
  },
]

export const connectorKwhStepOptions = [
  {
    value: 3,
  },
  {
    value: 7,
  },
  {
    value: 22,
  },
]

const getStationList = async (auth: any): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    getEvStationList(auth)
      .then((data) => {
        resolve(data?.data?.docs)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const CreateCharger = (props: any): JSX.Element => {
  const { dataCharger } = props || {}
  const {
    chargerCode,
    stationId,
    deviceType,
    serviceGroup,
    deviceInfo,
    deviceProtocol,
    _id,
    connectors,
  } = props?.dataCharger || {}
  const transformedConnectorProps = connectors?.map((item: any) => {
    const { connectorSelector, connectorStatus, connectorType, maxKw, priceId, kwSteps } = item
    const { code } = connectorType
    return {
      maxKw,
      priceId,
      connectorSelector,
      connectorTypeCode: code,
      connectorStatus,
      kwSteps,
    }
  })
  const { vendor, model, serialNumber, firmwareVersion } = deviceInfo || {}
  const [changeComponent, setChangeComponent] = useState(true)
  const [stationList, setStationList] = useState([])
  const [pricingList, setPricingList] = useState([])
  const [form] = Form.useForm()
  const connectorsList = Form.useWatch('connectorsList', form)
  const Auth = useContext<any>(AuthContext)
  function handleChangePage() {
    setChangeComponent(false)
  }
  const onReset = () => {
    form.resetFields()
  }
  const createCharger = async (data: IPostCharger) => {
    const chargerStatus = 'AVAILABLE'
    const controlDevice = null
    const serviceStatus = 'SERVICE'
    const connectorStatus = 'AVAILABLE'
    const {
      stationId,
      chargerCode,
      deviceType,
      serviceGroup,
      deviceProtocol,
      vendor,
      model,
      serialNumber,
      firmwareVersion,
      connectorsList,
    } = data
    const transformedConnectorLists = connectorsList?.map((item: any) => {
      const { maxKw, connectorSelector, connectorTypeCode, priceId, kwSteps } = item
      const mappedConnectorType = connectorTypes.filter(
        (connector) => connector.code === connectorTypeCode,
      )
      return {
        maxKw,
        kwSteps,
        connectorSelector,
        connectorType: mappedConnectorType[0],
        connectorStatus,
        priceId,
      }
    })
    const postdata: ICreateCharger = {
      serviceStatus,
      controlDevice,
      stationId,
      chargerCode,
      chargerStatus,
      deviceType,
      deviceProtocol,
      serviceGroup,
      connectors: transformedConnectorLists,
      deviceInfo: {
        vendor,
        model,
        serialNumber,
        firmwareVersion,
      },
    }
    // !_id
    //   ? await createEvCharger(postdata, Auth).then((res: any) => {
    //       Notification('Create Successfully', 'success')
    //     })
    //   : await updateEvCharger(postdata, Auth, _id)
    //       .then((res: any) => {
    //         Notification('Update Successfully', 'success')
    //       })
    //       .catch((error: any) => {
    //         Notification(`${error}`, error)
    //       })
    await form.resetFields()
    await setChangeComponent(false)
  }

  useEffect(() => {
    getStationList(Auth).then((result: any) => {
      return setStationList(result)
    })
    getPricingList(Auth).then((result: any) => {
      const { docs } = result?.data
      return setPricingList(docs)
    })
  }, [])

  return (
    <>
      {changeComponent ? (
        <>
          <Row>
            <Title level={1}>{!_id ? 'Add Charger' : 'Edit Charger'}</Title>
            <ButtonGlobal
              shape="round"
              type="primary"
              style={{ top: 11, left: 10 }}
              onClick={handleChangePage}
            >
              charger list
            </ButtonGlobal>
          </Row>
          <hr style={{ marginBottom: 20 }} />

          <Col span={15} offset={5}>
            <Form
              // onValuesChange={(v) => console.log(v)}
              onFinish={createCharger}
              form={form}
              initialValues={{
                vendor,
                model,
                serialNumber,
                firmwareVersion,
                stationId,
                chargerCode,
                deviceType,
                serviceGroup,
                deviceProtocol,
                connectorsList: transformedConnectorProps,
              }}
            >
              <Row gutter={10}>
                <Col md={12} sm={24} xs={24}>
                  <Title level={5}>Device Info</Title>
                  <Form.Item
                    name="vendor"
                    label="Vendor"
                    // rules={[{ required: true, message: 'Please input vendor!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="model"
                    label="Model"
                    style={{ marginTop: 31 }}
                    // rules={[{ required: true, message: 'Please input model!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="serialNumber"
                    label="Serial"
                    // rules={[{ required: true, message: 'Please input serial!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="firmwareVersion"
                    label="Version"
                    // rules={[{ required: true, message: 'Please input version!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Title level={5}>StationId</Title>
              <Form.Item
                name="stationId"
                rules={[{ required: true, message: 'Please input stationId!' }]}
              >
                <Select placeholder="select station">
                  {stationList.map((item: any) => {
                    return <Option value={item._id}>{item.stationName}</Option>
                  })}
                </Select>
              </Form.Item>
              <Row gutter={16}>
                <Col md={24} sm={24} xs={24}>
                  <Title level={5}>ChargerCode</Title>
                  <Form.Item
                    name="chargerCode"
                    rules={[{ required: true, message: 'Please input chargerCode!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col md={12} sm={24} xs={24}>
                  <Title level={5}>DeviceType</Title>
                  <Form.Item
                    name="deviceType"
                    rules={[{ required: true, message: 'Please select deviceType!' }]}
                  >
                    <Select placeholder="select deviceType">
                      <Option value="EV_CHARGER">EV_CHARGER</Option>
                      <Option value="SMART_PLUG">SMART_PLUG</Option>
                      <Option value="SMART_METER">SMART_METER</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <Title level={5}>ServiceGroup</Title>
                  <Form.Item
                    name="serviceGroup"
                    rules={[{ required: true, message: 'Please select serviceGroup!' }]}
                  >
                    <Select placeholder="select serviceGroup">
                      <Option value="INTERNAL">INTERNAL</Option>
                      <Option value="PUBLIC">PUBLIC</Option>
                      {/* <Option value="ALL">ALL</Option> */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Title level={5}>DeviceProtocol</Title>
              <Form.Item
                name="deviceProtocol"
                rules={[{ required: true, message: 'Please select deviceProtocol!' }]}
              >
                <Select placeholder="select deviceProtocol">
                  <Option value="OCPP">OCPP</Option>
                  <Option value="HAUP">HAUP</Option>
                </Select>
              </Form.Item>
              <Form.List name="connectorsList">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map(({ key, name, ...restField }) => {
                        const { maxKw } = (connectorsList && connectorsList[name]) || {}
                        return (
                          <Row gutter={10}>
                            <Col span={24} md={12}>
                              <Title level={5}>
                                <Popover content="Delete Field">
                                  <MinusCircleOutlined onClick={() => remove(name)} />
                                </Popover>
                                Connectors
                              </Title>
                              <Form.Item
                                {...restField}
                                name={[name, 'connectorTypeCode']}
                                rules={[{ required: true, message: 'Please select connectors!' }]}
                              >
                                <Select placeholder="select connectors">
                                  {connectorTypes.map((option: IConnectorType) => (
                                    <Option key={option.code} value={option.code}>
                                      {option.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col span={24} md={12}>
                              <Title level={5}>Connector selector</Title>
                              <Form.Item
                                {...restField}
                                name={[name, 'connectorSelector']}
                                rules={[
                                  { required: true, message: 'Please select connector selector!' },
                                ]}
                              >
                                <Input placeholder="1" />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'maxKw']}
                                label="MaxKw"
                                rules={[{ required: true, message: 'Please input maxKw!' }]}
                              >
                                <InputNumber type="number" controls={false} />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, 'kwSteps']}
                                label="Kw Steps"
                                initialValue={[]}
                                normalize={(value, prev) => {
                                  if (Array.isArray(value)) {
                                    const numberedValues = value.map(Number)
                                    if (numberedValues.some(isNaN)) {
                                      return prev || []
                                    }
                                    if (
                                      numberedValues.some(
                                        (n) => numberedValues.filter((v) => v === n).length >= 2,
                                      )
                                    ) {
                                      return prev || []
                                    }
                                    if (maxKw && numberedValues.some((n) => n > maxKw)) {
                                      return prev || []
                                    }
                                    return numberedValues
                                  }
                                  return prev || []
                                }}
                              >
                                <Select mode="tags" options={connectorKwhStepOptions} />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item {...restField} name={[name, 'priceId']} label="pricing">
                                <Select placeholder="select pricing">
                                  {pricingList.map((option: any) => (
                                    <Option key={option._id} value={option._id}>
                                      {option._id} - {option.priceCode}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        )
                      })}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add Connector Field
                        </Button>
                      </Form.Item>
                    </>
                  )
                }}
              </Form.List>

              <hr style={{ marginBottom: 20 }} />
              <Row gutter={[16, 50]}>
                <Col>
                  <ViewButton shape="round" htmlType="submit">
                    {_id ? 'Edit Charger' : 'Submit'}
                  </ViewButton>
                </Col>
                {dataCharger == null ? (
                  <Col>
                    <ButtonGlobal shape="round" htmlType="button" onClick={onReset}>
                      Reset
                    </ButtonGlobal>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </Form>
          </Col>
        </>
      ) : (
        <ViewCharger />
      )}
    </>
  )
}

export default CreateCharger
