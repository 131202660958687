import { Col, Layout, Row, Space, Typography } from 'antd'
import React from 'react'
import { Redirect } from 'react-router'
import { clearSession } from '../../contexts/Auth'
import Loginbg from '../../static/img/loginbg.jpg'
import LogoHaup from '../../static/img/logo2.png'

const { Title } = Typography

// interface ILogoutState {
//   isUserLoggedOut: Promise<boolean>
// }

class Logout extends React.Component<any, { isUserLoggedOut: boolean | null }> {
  constructor(props: any) {
    super(props)
    this.state = { isUserLoggedOut: null }
  }

  async componentDidMount() {
    const result = await clearSession()
    this.setState({ isUserLoggedOut: result })
  }

  render(): JSX.Element {
    const { isUserLoggedOut } = this.state

    return (
      <>
        {isUserLoggedOut && <Redirect to="/login" />}
        <Layout
          style={{
            minHeight: '100vh',
            backgroundImage: `url(${Loginbg})`,
            backgroundSize: 'cover',
          }}
        >
          <Row justify="space-around" align="middle" style={{ minHeight: '100vh' }}>
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Row style={{ minWidth: '100%' }} justify="center">
                <Col span={8} style={{ textAlign: 'center' }}>
                  <img src={LogoHaup} height="40vh" />
                </Col>
              </Row>
              <Row style={{ minWidth: '100%' }} justify="center">
                <Col
                  span={8}
                  style={{ backgroundColor: 'white', textAlign: 'center', padding: '50px' }}
                >
                  <Title>You are logged out</Title>
                </Col>
              </Row>
              <Row style={{ minWidth: '100%' }} justify="center">
                <Col span={8} style={{ textAlign: 'center', color: 'white' }}>
                  <p>© 2021 Haupcar Company Limited.</p>
                </Col>
              </Row>
            </Space>
          </Row>
        </Layout>
      </>
    )
  }
}

export default Logout
