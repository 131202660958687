import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
} from 'antd'
import moment from 'moment-timezone'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import {
  BooleanParam,
  DateParam,
  NumberParam,
  QueryParamProvider,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { DateDatePicker } from '../../components/DatePicker/DateDatePicker'
import HeaderSection from '../../components/HeaderSection'
import { PartialNull } from '../../libs/type-utils'
import { getEvCharger } from '../../services/evcharger'
import { errorMessage } from '../../utils/axios-error'
import { useAuth } from './../../contexts/Auth'
import { getReportCSV, getReservationList } from './../../services/evreservation'
import { getEvStationList } from './../../services/evstation'
import ReservationDetail from './ReservationDetail'
import ReservationList from './ReservationList'

const { Option } = Select

const sortByOptions = [
  {
    label: 'Reservation: No',
    value: '_id',
  },
  {
    label: 'Reservation: Time',
    value: 'createdAt',
  },
  {
    label: 'Note: Create Time',
    value: 'notes.0.createdAt',
  },
  {
    label: 'Note: Update Time',
    value: 'notes.0.updatedAt',
  },
]

const sortOrderOptions = [
  {
    label: (
      <>
        <SortAscendingOutlined /> Ascending
      </>
    ),
    value: 'asc',
  },
  {
    label: (
      <>
        <SortDescendingOutlined /> Descending
      </>
    ),
    value: 'desc',
  },
]

function transformReservationData(rawData: any[]) {
  return rawData.map((item) => {
    const {
      reservationNo,
      status,
      userId,
      nfcId,
      mobile,
      cardType,
      receiptNo,
      receiptFile,
      station,
      charger,
      deviceType,
      reservationStartTime,
      reservationStopTime,
      reserveDurationMinute,
      startChargingTime,
      stopChargingTime,
      usageDurationMinute,
      energy_kWh,
      revenue,
      paymentType,
      channel,
      totalRefunded,
      notes,
    } = item

    const usageData = {
      startTime: startChargingTime
        ? moment(startChargingTime).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        : '-',
      stopTime: stopChargingTime
        ? moment(stopChargingTime).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        : '-',
      chargedTime: usageDurationMinute || '-',
      chargedKwh: energy_kWh || '-',
    }

    const transformedData = {
      key: reservationNo,
      reservationStatus: { reservationNo, status },
      customer: {
        userId,
        nfcId,
        mobile,
        cardType,
      },
      station,
      charger: {
        chargerCode: charger,
        deviceType,
      },
      reserveTime: {
        actualStartTime: moment(reservationStartTime)
          .tz('Asia/Bangkok')
          .format('YYYY-MM-DD HH:mm:ss'),
        actualStopTime: moment(reservationStopTime)
          .tz('Asia/Bangkok')
          .format('YYYY-MM-DD HH:mm:ss'),
        actualReserveMinute: reserveDurationMinute,
      },
      usageData,
      revenue: {
        revenue,
        totalRefunded,
        paymentType: paymentType || 'Free',
      },
      channel,
      receipt: {
        receiptNo,
        receiptFile,
      },
      notes,
    }

    return transformedData
  })
}

interface IStationList {
  _id: number
  stationName: string
}

interface IChargerList {
  _id: number
  chargerCode: string
}

interface IPagination {
  current: number
  pageSize: number
  total?: number
}

export type Filters = { page: number; pageSize: number } & Partial<
  PartialNull<{
    refunded: boolean
    noted: boolean
    sortBy: string
    sortOrder: string
    query: string
    stationId: number
    mobile: string
    startTime: Date
    stopTime: Date
    status: string
    reservationId: number
    chargerId: number
  }>
>

const ReservationWithQueryProvider = ({ children }: { children: ReactNode }) => {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter5Adapter}>{children}</QueryParamProvider>
    </BrowserRouter>
  )
}

const Reservation = () => {
  return (
    <ReservationWithQueryProvider>
      <ReservationContent />
    </ReservationWithQueryProvider>
  )
}

const ReservationContent = () => {
  const auth = useAuth()

  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
  const [pageSize, setPageSize] = useQueryParam('pageSize', withDefault(NumberParam, 50))
  const [refunded, setRefunded] = useQueryParam('refunded', BooleanParam)
  const [noted, setNoted] = useQueryParam('noted', BooleanParam)
  const [sortBy, setSortBy] = useQueryParam('sortBy', StringParam)
  const [sortOrder, setSortOrder] = useQueryParam('sortOrder', StringParam)
  const [query, setQuery] = useQueryParam('query', StringParam)
  const [stationId, setStationId] = useQueryParam('stationId', NumberParam)
  const [mobile, setMobile] = useQueryParam('mobile', StringParam)
  const [startTime, setStartTime] = useQueryParam('startTime', DateParam)
  const [stopTime, setStopTime] = useQueryParam('stopTime', DateParam)
  const [status, setStatus] = useQueryParam('status', StringParam)
  const [reservationId, setReservationId] = useQueryParam('reservationId', NumberParam)
  const [chargerId, setChargerId] = useQueryParam('chargerId', NumberParam)

  const filters = useMemo(
    () => ({
      page,
      pageSize,
      refunded,
      noted,
      sortBy,
      sortOrder,
      query,
      stationId,
      mobile,
      startTime,
      stopTime,
      status,
      reservationId,
      chargerId,
    }),
    [
      page,
      pageSize,
      refunded,
      noted,
      sortBy,
      sortOrder,
      query,
      stationId,
      mobile,
      startTime,
      stopTime,
      status,
      reservationId,
      chargerId,
    ],
  )

  const [filterForm] = Form.useForm()
  const [currentReservationId, setCurrentReservationId] = useState(0)
  const [stationList, setStationList] = useState<any[]>([])
  const [reservationList, setReservationList] = useState<any[]>([])
  const [reservationCountTotal, setReservationCountTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isExportModalVisible, setIsExportModalVisible] = useState(false)
  const [loadingExport, setLoadingExport] = useState(true)
  const [exportCSVContent, setExportCSVContent] = useState('')
  const [filterOpen, setFilterOpen] = useState(false)
  const [chargerList, setChargerList] = useState<any[]>([])

  function getReservationDetail(reservationId: number) {
    setCurrentReservationId(reservationId)
  }

  function fetchReservation() {
    setLoading(true)
    getReservationList(auth, filters).then((response: any) => {
      const { docs, totalDocs } = response?.data
      setReservationList(docs)
      setReservationCountTotal(totalDocs)
      setLoading(false)
    })
  }

  function handleTableChange(pagination: IPagination) {
    setPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  function onApplyFilter(
    data: Pick<
      Filters,
      | 'noted'
      | 'query'
      | 'refunded'
      | 'sortBy'
      | 'sortOrder'
      | 'stationId'
      | 'status'
      | 'startTime'
      | 'stopTime'
      | 'chargerId'
    >,
  ) {
    setFilterOpen(false)
    const {
      noted,
      query,
      refunded,
      sortBy,
      sortOrder,
      startTime,
      stopTime,
      stationId,
      status,
      chargerId,
    } = data
    setNoted(noted)
    setQuery(query)
    setRefunded(refunded)
    setSortBy(sortBy)
    setSortOrder(sortOrder)
    setStartTime(startTime)
    setStopTime(stopTime)
    setStationId(stationId)
    setStatus(status)
    setChargerId(chargerId)
    setPage(1)
  }

  async function showModal() {
    setIsExportModalVisible(true)
    getReportCSV(auth, filters)
      .then((result) => {
        const universalBOM = '\uFEFF'
        const content =
          'data:application/csv;charset=utf-8,' + encodeURIComponent(universalBOM + result)
        setExportCSVContent(content)
      })
      .catch((error) =>
        Modal.error({ content: errorMessage(error), onOk: () => window.location.reload() }),
      )
      .finally(() => {
        setLoadingExport(false)
      })
  }

  function handleOk() {
    setIsExportModalVisible(false)
    setExportCSVContent('')
  }

  function handleCancel() {
    setIsExportModalVisible(false)
    setExportCSVContent('')
  }

  function onCloseDetail() {
    setCurrentReservationId(0)
  }

  useEffect(() => {
    filterForm.setFieldsValue(filters)
    fetchReservation()
  }, [filters])

  useEffect(() => {
    getEvStationList(auth).then((result: { data: any }) => {
      setStationList(result.data.docs)
    })
    getEvCharger(auth, { page: 1, limit: 200 }).then((res) => {
      if (res?.data?.docs) {
        setChargerList(res.data.docs)
      }
    })
  }, [])

  return (
    <>
      {/* {console.log('===== return =====')} */}

      {/* <h1>EV Reservation</h1> */}
      <div
        style={{
          height: 'calc(100% - 100px)',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <HeaderSection
          tmpBreadcrumb={[
            {
              title: 'EVCharger /',
            },
            {
              title: 'View Reservation',
              link: '/charger/reservation',
            },
          ]}
          title="Ev Reservation"
          buttonTitle="create"
          linkCreate="reservation/create"
          haveCreateButton={false}
        />
        <br />
        <Collapse
          activeKey={filterOpen === true ? '1' : '0'}
          onChange={() => setFilterOpen((prev) => !prev)}
        >
          <Collapse.Panel header="Filters" key={'1'}>
            <Form form={filterForm} onFinish={onApplyFilter}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="query" label="Search">
                    <Input placeholder="reservation, mobile, nfc, user" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="status" label="Status">
                    <Select style={{ width: 120 }}>
                      <Option value="">ALL</Option>
                      <Option value="REQUEST">REQUEST</Option>
                      <Option value="RESERVE">RESERVE</Option>
                      <Option value="ON_GOING">ON_GOING</Option>
                      <Option value="FINISH">FINISH</Option>
                      <Option value="COMPLETE">COMPLETE</Option>
                      <Option value="REJECT">REJECT</Option>
                      <Option value="CANCEL">CANCEL</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="stationId" label="Station">
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      allowClear
                    >
                      <Option>ALL-STATION</Option>
                      {stationList.map((option: IStationList) => (
                        <Option key={option._id} value={option._id}>
                          ({option._id}) {option.stationName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="chargerId" label="Charger">
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      allowClear
                    >
                      <Option>ALL-CHARGER</Option>
                      {chargerList.map((option: IChargerList) => (
                        <Option key={option._id} value={option._id}>
                          ({option._id}) {option.chargerCode}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Space size="large">
                    <Space wrap>
                      <Form.Item name="startTime" label="From">
                        <DateDatePicker format="YYYY-MM-DD" />
                      </Form.Item>
                      <Form.Item name="stopTime" label="To">
                        <DateDatePicker format="YYYY-MM-DD" />
                      </Form.Item>
                    </Space>
                    <Space>
                      <Form.Item name="refunded" valuePropName="checked">
                        <Checkbox>Only refunded</Checkbox>
                      </Form.Item>
                      <Form.Item name="noted" valuePropName="checked">
                        <Checkbox>Only noted</Checkbox>
                      </Form.Item>
                    </Space>
                  </Space>
                </Col>
              </Row>
              <Row justify={'space-between'}>
                <Col>
                  <Space wrap>
                    <Form.Item label="Sort By" name="sortBy">
                      <Select style={{ minWidth: 225 }} options={sortByOptions} />
                    </Form.Item>
                    <Form.Item label="Sort Order" name="sortOrder">
                      <Select style={{ minWidth: 150 }} options={sortOrderOptions} />
                    </Form.Item>
                  </Space>
                </Col>
                <Col>
                  <Space>
                    <Form.Item>
                      <Button onClick={showModal}>Export</Button>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Search
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Collapse.Panel>
        </Collapse>
        <br />
        <ReservationList
          dataSource={transformReservationData(reservationList)}
          getReservationDetail={getReservationDetail}
          pagination={{
            page: filters.page,
            pageSize: filters.pageSize,
            total: reservationCountTotal,
          }}
          onTableChange={handleTableChange}
          loading={loading}
        />

        {currentReservationId > 0 && (
          <Drawer
            open={true}
            onClose={onCloseDetail}
            placement="right"
            style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}
          >
            {/* <ChargerProgressBar reservationId={currentReservationId} /> */}
            <ReservationDetail reservationId={currentReservationId} onClose={onCloseDetail} />
          </Drawer>
        )}
      </div>
      <Modal
        title="Export Reservation"
        open={isExportModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ textAlign: 'center' }}
        footer={[
          <Button
            key="link"
            href={exportCSVContent}
            type="primary"
            loading={loadingExport}
            download="report.csv"
            onClick={handleOk}
          >
            Download
          </Button>,
        ]}
      >
        {loadingExport ? (
          <Space direction="vertical">
            <Spin tip="Loading..."></Spin>
            <Alert type="info" message="Please wait. This might take up to 3 minutes" />
          </Space>
        ) : (
          'Report file is ready to download.'
        )}
      </Modal>
    </>
  )
}

export default Reservation
